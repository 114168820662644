import Utils from '../utils'
class ReviewContent extends HTMLElement {
  constructor() {
    super()
    this._$root = this.attachShadow({ mode: 'open' })
  }

  getTypeConfigs(configType = null) {
    const configTypes = {
      'cad-highs': {
        hiddenContent: 'No highs to display',
        displayContent: this._vehicleData.highs,
        label: 'Car and Driver Highs'
      },
      'cad-lows': {
        hiddenContent: 'No lows to display',
        displayContent: this._vehicleData.lows,
        label: 'Car and Driver Lows'
      },
      'cad-verdict': {
        hiddenContent: 'No verdict to display',
        displayContent: this._vehicleData.verdict,
        label: 'Car and Driver Verdict'
      },
      'cad-overview': {
        hiddenContent: 'No overview to display',
        displayContent: this._vehicleData.overview,
        label: 'Car and Driver Overview'
      }
    }

    return (configType)
      ? configTypes[configType]
      : configTypes
  }

  /**
   * Invoked when the custom element connects to the DOM
   *
   * Responsible for either adding the SVG badge with a custom title
   * or a comment indicating the chosen vehicle was not awarded that
   * badge.
   *
   * @memberof Badge
   */
  connectedCallback() {
    this._$vehicleRoot = this.closest('ha-vehicle')

    // Wait to populate elements until vehicle data has been added from API
    this._$vehicleRoot.addEventListener('onHaVehicleDataAdded', () => {
      this._vehicleData = this._$vehicleRoot._vehicleData
      this._type = this.getAttribute("type")
      this._config = this.getTypeConfigs(this._type)

      if (!this._config) {
        throw new Error(
          // prettier-ignore
          `Accepted type attributes for <ha-review-content> must be: ${Object.keys(this.getTypeConfigs()).join(" or ")}. Provided type was: "${this._type}".`
        )
      }

      const content = this._html()
      if (!content) {
        this._$root.innerHTML = Utils.hiddenHTML(this._config.hiddenContent)
        return
      }

      this._$root.innerHTML = `
        <style>
          label {
            font-weight: bold;
          }
        </style>
    ` + content
    })
  }

  /**
   * Returns HTML based on the config
   *
   * @returns {string} raw display content
   * @memberof ReviewContent
   */
  _html() {
    return (!this._config.displayContent)
      ? null
      : `<div><label>${this._config.label}:</label> ${this._config.displayContent}</div>`
  }
}

export default ReviewContent

import Axios from "axios"

module.exports = {
  /**
   * Loads the config file from the generated sdk-config.json file
   * @returns {Promise<{}|T>}
   */
  loadConfig: async function() {
    if (typeof window.HA.config === 'undefined') {
      const myScript = document.currentScript,
        mySrc = myScript.getAttribute('src')
      const pathSegments = mySrc.split('/')
      pathSegments.pop()
      pathSegments.push('sdk-config.json')
      const configPath = pathSegments.join('/')
      const configResponse = await Axios.get(configPath)
      if (!configResponse || !configResponse.data) {
        console.error(`HA: Error fetching config from ${configPath}`)
        return {}
      }
      window.HA.config = configResponse.data
    }

    return window.HA.config
  },

  /**
   * 
   * @param {String} key - string of a desired key to save to config 
   * @param {String} value - string of a desired value to save to the config
   * @returns 
   */
  addToConfig: function(key, value) {
    if (!window.HA || !window.HA.config) {
      console.error(`HA: Error setting ${key} config with a value of ${value}`)
      return {}
    }
    
    window.HA.config[key] = value
    return {}
  },

  /**
   * Shorthand to get the config loaded into window.HA.config
   * @returns {{}|T}
   */
  getConfig: function() {
    if (!window.HA || !window.HA.config) {
      return {}
    }
    return window.HA.config
  }
}

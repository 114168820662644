import 'dotenv/config' // optionally store your environment variables in .env

const nhtsaHost = process.env.NHTSA_HOST || 'https://vpic.nhtsa.dot.gov'

export default {
  chromeAPI: process.env.CHROMEAPI_HOST || 'https://ha-chromedata-api.kubefeature.hearstapps.net',
  datadogHost: process.env.DATADOG_HOST || '',
  datadogServiceName: process.env.DATADOG_SERVICE_NAME || '',
  datadogEnv: process.env.DATADOG_ENV || '',
  datadogTraceAnalyticsEnabled: String(process.env.DATADOG_TRACE_ANALYTICS_ENABLED).toLowerCase() === 'true',
  datadogTraceEnabled: String(process.env.DATADOG_TRACE_ENABLED).toLowerCase() === 'true',
  vdatAPI: process.env.VDATAPI_HOST || 'https://ha-vdat-api.kubefeature.hearstapps.net',
  nhtsaHost,
  nhtsaAPI: process.env.NHTSA_API || `${nhtsaHost}/api`,
  maxAllowedVins: 50,
  sentryDsn: {
    api: process.env.SENTRY_DSN_API || '',
    sdk: process.env.SENTRY_DSN_SDK || ''
  }
}
const SUPPORTED_BACKGROUND_COLORS = ['transparent', 'white']

class SVG extends HTMLElement {
  /**
   * Default style attributes for the Rating and Badge elements.
   *
   * Properly sizes the SVG image and adds a background color. The background
   * color can be "transparent" (the default style) or "white" (user-defined).
   *
   * @returns {string} Default SVG styles
   * @memberof SVG
   */
  innerHTMLStyle() {
    return `
    <style>
      :host {
        display: inline-block;
        width: 100%;
      }

      img {
        background-color: ${this.backgroundColor};
        border-radius: 100%;
        width: 100%;
      }
    </style>`
  }

  /**
   * Gets "background-color" attribute of the SVG.
   *
   * If the SVG does not have a defined background-color attribute we return
   * the the transparent color. A badge with a transparent background is the
   * default color state for the badge.
   *
   * If a color is defined AND it belongs to our supported background colors,
   * return the color. Otherwise, throw an error indicating that the supplied
   * color is useable.
   *
   * @returns {string} Background color defined on element or transparent if no color defined.
   * @readonly
   * @memberof SVG
   */
  get backgroundColor() {
    const elementBackgroundColor = this.getAttribute('background-color')
    if (!elementBackgroundColor) return 'transparent'

    if (!SUPPORTED_BACKGROUND_COLORS.includes(elementBackgroundColor)) {
      throw new Error(
        // prettier-ignore
        `The provided background-color attribute must be: ${SUPPORTED_BACKGROUND_COLORS.join(" or ")}. Provided color was: "${elementBackgroundColor}".`
      )
    }

    return elementBackgroundColor
  }
}

export default SVG

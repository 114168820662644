import * as Sentry from '@sentry/browser'

import Badge from './components/badge'
import Rating from './components/rating'
import Vehicle from './components/vehicle'
import ReviewContent from './components/review-content'
import Config from './config'

import config from '../config'

const HA = {
  /**
   * Initialize the HA app
   *
   * @param {*} params
   */
  init: async function (params) {
    if (config.sentryDsn.sdk) {
      Sentry.init({
        dsn: config.sentryDsn.sdk,
        environment: process.env.NODE_ENV
      })
    }

    // Validate the parameters
    if (typeof params.appId === 'undefined') {
      throw new Error('HA.init must be called with an appId parameter')
    }

    if (params.minimumRating) {
      if (typeof params.minimumRating !== 'number') {
        throw new Error(
          "'minimumRating' value passed to HA.init must be a valid number"
        )
      }

      if (params.minimumRating < 0 || params.minimumRating > 10) {
        throw new Error("'minimumRating' value must be between 0 and 10")
      }
    }

    // load environment generated config
    await Config.loadConfig()

    // add appId to configuration
    Config.addToConfig('APP_ID', params.appId)

    // define web components
    window.customElements.define('ha-vehicle', Vehicle)
    window.customElements.define('ha-badge', Badge)
    window.customElements.define('ha-rating', Rating)
    window.customElements.define('ha-review-content', ReviewContent)

    document.addEventListener('DOMContentLoaded', () => {
      this.setDefaultMinimumRatingValue(params.minimumRating)
    })
  },

  setDefaultMinimumRatingValue(minimumRating) {
    const elems = document.querySelectorAll('ha-rating')
    elems.forEach((elem) => {
      elem.defaultMinimumRating = minimumRating
    })
  }
}

export default HA

import axios from 'axios'
import config from './config'

export default {
  // vinTimeout used to set and reset the timeout for the setVin Method.
  vinTimeout: null,

  // vins array is used to make the bulk vehicle api call
  vins: [],

  // vehicleData is an object that contains the response from the bulk vehicle api calls
  vehicleData: {},

  /**
   * Method that takes a vin number and adds it to an array, if the method receives
   * consecutive vins within 20ms of each other they will also be added to that array
   * to do a bulk api call.
   *
   * @param vin
   */
  setVin: function (vin) {
    if (vin) {
      // Push VIN to vin array
      this.vins.push(vin)

      // Clear timeout if we receive another call to not overwhelm API calls
      clearTimeout(this.vinTimeout)

      // Set timeout, if this doesn't get cleared we will make a batch api call.
      this.vinTimeout = setTimeout(async () => {
        // API Call to retun vehicle data.
        this.vehicleData = await this.getVehicleData(this.vins)

        // Query Selector to update DOM elements fetched attributes.
        let test =
          'ha-vehicle[vin="' + this.vins.join('"], ha-vehicle[vin="') + '"]'
        document
          .querySelectorAll(test)
          .forEach((x) => (x.dataset.fetched = 'true'))

        // Reset vins array for next batch.
        this.vins = []
      }, 20)
    }
  },

  /**
   * Getter method that returns vin specific data that was set by getVehicleData method (API Call)
   *
   * @param vin
   * @returns {*|null}
   */
  getDataFromVin: function (vin) {
    return (
      this.vehicleData.data.filter((obj) => {
        return obj.vins.includes(vin)
      })[0] || null
    )
  },

  /**
   * Fetches data from the API
   * @param vins
   * @returns {Object}
   */
  getVehicleData: async function (vins = []) {
    try {
      const result = await axios.get(
        `${config.getConfig().HA_API_HOST}/v2/vehicles/${vins.join(',')}`, 
        {
          headers: {
            Authorization: config.getConfig().APP_ID
          }
        }
      )
      if (
        result &&
        result.data &&
        result.data.errors &&
        result.data.errors.length
      ) {
        result.data.errors.forEach((message) => console.info(message))
      }
      return result.data
    } catch (ex) {
      console.error(`HA SDK: ${ex.message}`)
      return null
    }
  },

  /**
   * Creates the badge. Gets the asset path and adds in alt text.
   *
   * @param {*} path - Path to asset
   * @param {*} altText - Alt text for image
   * @returns {element} SVG badge element with appropriate title
   */
  createImage(path, altText) {
    const img = document.createElement('img')
    if (altText === undefined || altText === '') {
      throw new Error('Descriptive image alternative text must be provided.')
    }

    img.src = path
    img.alt = altText

    return img
  },

  /**
   * Creates a comment that is used in cases where a vehicle does not have
   * an associated badge
   *
   * @returns {string} Comment indicating badge does not exist

   */
  hiddenHTML(string) {
    return `<!-- HA: ${string} -->`
  },

  /**
   * Map of of badge IDs to their locations in the assets directory
   */
  badgeLocations: function() {
    const SVG_PATH = `${config.getConfig().HA_API_HOST}/assets/images`
    return {
      'cad-10best': `${SVG_PATH}/ten-best-badge.svg`,
      'cad-editors-choice': `${SVG_PATH}/editors-choice-badge.svg`,
      'cad-ev-of-the-year': `${SVG_PATH}/ev-of-the-year-badge.svg`,
      0: `${SVG_PATH}/rating-0.svg`,
      '0.5': `${SVG_PATH}/rating-0.5.svg`,
      1: `${SVG_PATH}/rating-1.svg`,
      '1.5': `${SVG_PATH}/rating-1.5.svg`,
      2: `${SVG_PATH}/rating-2.svg`,
      '2.5': `${SVG_PATH}/rating-2.5.svg`,
      3: `${SVG_PATH}/rating-3.svg`,
      '3.5': `${SVG_PATH}/rating-3.5.svg`,
      4: `${SVG_PATH}/rating-4.svg`,
      '4.5': `${SVG_PATH}/rating-4.5.svg`,
      5: `${SVG_PATH}/rating-5.svg`,
      '5.5': `${SVG_PATH}/rating-5.5.svg`,
      6: `${SVG_PATH}/rating-6.svg`,
      '6.5': `${SVG_PATH}/rating-6.5.svg`,
      7: `${SVG_PATH}/rating-7.svg`,
      '7.5': `${SVG_PATH}/rating-7.5.svg`,
      8: `${SVG_PATH}/rating-8.svg`,
      '8.5': `${SVG_PATH}/rating-8.5.svg`,
      9: `${SVG_PATH}/rating-9.svg`,
      '9.5': `${SVG_PATH}/rating-9.5.svg`,
      10: `${SVG_PATH}/rating-10.svg`
    }
  },

  /**
   * Dynamically load a script file
   * @param url
   * @param callback
   */
  loadScript(url, callback){
    const script = document.createElement("script")
    script.type = "text/javascript"

    if (script.readyState){  //IE
      script.onreadystatechange = function(){
        if (script.readyState == "loaded" ||
          script.readyState == "complete"){
          script.onreadystatechange = null
          callback()
        }
      }
    } else {  //Others
      script.onload = function(){
        callback()
      }
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
  }
}

import Utils from '../utils'

import SVG from './svg'

class Badge extends SVG {
  constructor() {
    super()
    this._$root = this.attachShadow({ mode: 'open' })
  }

  getTypeConfigs(configType = null) {
    const configTypes = {
      'cad-10best': {
        hiddenContent: Utils.hiddenHTML('Not a 10Best vehicle. Badge hidden.'),
        badgePath: Utils.badgeLocations()['cad-10best'],
        altText: 'Car & Driver 10 Best Vehicle',
        isDisplayed: this._vehicleData.accolades.is_ten_best
      },
      'cad-editors-choice': {
        hiddenContent: Utils.hiddenHTML("Not an Editor's Choice vehicle. Badge hidden."),
        badgePath: Utils.badgeLocations()['cad-editors-choice'],
        altText: "Car & Driver Editor's Choice Vehicle",
        isDisplayed: this._vehicleData.accolades.is_editors_choice
      },
      'cad-ev-of-the-year': {
        hiddenContent: Utils.hiddenHTML("Not an EV of the year vehicle. Badge hidden."),
        badgePath: Utils.badgeLocations()['cad-ev-of-the-year'],
        altText: "Car & Driver EV of the Year Vehicle",
        isDisplayed: this._vehicleData.accolades.is_ev_of_the_year
      }
    }

    return (configType)
      ? configTypes[configType]
      : configTypes
  }

  /**
   * Invoked when the custom element connects to the DOM
   *
   * Responsible for either adding the SVG badge with a custom title
   * or a comment indicating the chosen vehicle was not awarded that
   * badge.
   *
   * @memberof Badge
   */
  connectedCallback() {
    this._$vehicleRoot = this.closest('ha-vehicle')
    this._$vehicleRoot.addEventListener('onHaVehicleDataAdded', () => {
      this._vehicleData = this._$vehicleRoot._vehicleData
      this._type = this.getAttribute('type')
      this._config = this.getTypeConfigs(this._type)

      if (!this._config) {
        throw new Error(
          // prettier-ignore
          `Accepted type attributes for <ha-badge> must be: ${Object.keys(this.getTypeConfigs()).join(" or ")}. Provided type was: "${this._type}".`
        )
      }

      if (!this._config.isDisplayed) {
        this._$root.innerHTML = this._config.hiddenContent
        return
      }

      this._$root.innerHTML = this.innerHTMLStyle()

      const badge = Utils.createImage(
        this._config.badgePath,
        this._config.altText
      )
      this._$root.prepend(badge)
    })
  }
}

export default Badge

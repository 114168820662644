import Utils from '../utils'

import SVG from './svg'

class Rating extends SVG {
  constructor() {
    super()
    this._$root = this.attachShadow({ mode: 'open' })
  }

  /**
   * Responsible for displaying or hiding the badge of the rating component
   *
   * @memberof Rating
   */
  _calculateIfBadgeIsVisible() {
    const ratingThreshold = this._getRatingThreshold()
    const shouldDisplayRating = this._vehicleRating >= ratingThreshold

    if (!shouldDisplayRating) {
      this._$root.innerHTML = Utils.hiddenHTML('Vehicle rating hidden.')
      return
    }

    this._$root.innerHTML = this.innerHTMLStyle()

    const rating = this._createRating()
    this._$root.prepend(rating)
  }

  /**
   * Invoked when the custom element connects to the DOM
   *
   * @memberof Badge
   */
  connectedCallback() {
    this._$vehicleRoot = this.closest('ha-vehicle')
    this._$vehicleRoot.addEventListener('onHaVehicleDataAdded', () => {
      this._vehicleData = this._$vehicleRoot._vehicleData
      this._vehicleRating = this._vehicleData.accolades.rating

      this._calculateIfBadgeIsVisible()
    })
  }

  /**
   * Invoked whenever an attribute changes
   *
   * @memberof Badge
   */
  attributeChangedCallback(attrName) {
    if (attrName === 'default-minimum-rating') {
      this._calculateIfBadgeIsVisible()
    }
  }

  /**
   * The attributes that the element watches. attributeChangedCallback is called
   * whenever any of the attributes in the array are modified
   *
   * @readonly
   * @static
   * @memberof Rating
   */
  static get observedAttributes() {
    return ['default-minimum-rating']
  }

  /**
   * Gets the threshold for which a rating should be seen or not.
   * Defaults to the minimum-rating value set on a component. If
   * a value is not set, fallback to the default minimum rating
   * of all components. This value is set in the HA.init method
   * or is set to 0.
   *
   * @returns
   * @memberof Rating
   */
  _getRatingThreshold() {
    // Check to see if the minimum rating is set
    const minimumRating = this.minimumRating
    const defaultMinimumRating = this.defaultMinimumRating

    return !minimumRating ? defaultMinimumRating : minimumRating
  }

  /**
   * Returns the path to the SVG rating badge based on the rating of
   * the current vehicle
   *
   * @returns {string} Raw badge SVG
   * @memberof Badge
   */
  _badgePath() {
    return Utils.badgeLocations()[this._vehicleRating]
  }

  /**
   * Creates the alt text that is used to describe the rating
   *
   * @returns {string} Alt text used for the rating
   * @memberof Badge
   */
  _ratingAltText() {
    return `This vehicle was given a ${this._vehicleRating}/${this._vehicleData.accolades.rating_maximum} rating by Car & Driver editors.`
  }

  /**
   * Creates the rating badge wrapped in an <a> element that links to the car's review.
   * The anchor element can be removed if the `hide-review-url` is set to true.
   *
   * @returns {HTMLElement} Anchor element with href set as the car's C&D review
   * @memberof Rating
   */
  _createRating() {
    const image = Utils.createImage(this._badgePath(), this._ratingAltText())
    // If no URL exists in vehicle data we avoid creating an empty anchor element
    if (this.linkDisabled || !this._vehicleData.url) return image
    const anchorElement = document.createElement('a')
    anchorElement.href = this._vehicleData.url
    anchorElement.target = '_blank'
    anchorElement.appendChild(image)
    return anchorElement
  }

  /**
   * Gets the minimum rating
   *
   * @returns {number} Minimum rating value
   * @memberof Rating
   */
  get minimumRating() {
    return this.getAttribute('minimum-rating')
  }

  /**
   * Gets the default minimum rating that was passed from the HA.init method
   *
   * @returns {number} Minimum rating value
   * @memberof Rating
   */
  get defaultMinimumRating() {
    return this.getAttribute('default-minimum-rating') || 0
  }

  /**
   * Indicates if the rating should be wrapped in an anchor element pointing back
   * to Car and Driver's review for that vehicle.
   *
   * @returns {boolean} If rating should link back to Car and Driver
   * @memberof Rating
   */
  get linkDisabled() {
    return this.hasAttribute('link-disabled')
  }

  /**
   * Sets the defaultMinimumRatingValue
   *
   * Set from the HA.init() method. If no minimumValue is set, this method
   * is not called.
   *
   * If the rating is falsy (this would happen if a minimum value was
   * not initialized in HA.init()) set the default minimum value to
   * zero. With a zero value, all vehicles will be shown.
   *
   * @memberof Rating
   */
  set defaultMinimumRating(rating) {
    this.setAttribute('default-minimum-rating', rating || 0)
  }
}

export default Rating

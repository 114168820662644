import Utils from '../utils'

class Vehicle extends HTMLElement {
  constructor() {
    super()
    this._vehicleData = {}
  }

  /**
   * Invoked when the custom element connects to the DOM
   *
   * Responsible for retrieving vins and passing them to the API method.
   *
   * @memberof Vehicle
   */
  connectedCallback() {
    // Get the vin attribute
    this._vin = this.getAttribute('vin')

    // Set VIN to be used in API call
    Utils.setVin(this._vin)
  }

  /**
   * Static get method that defines which attributes will be observed
   * in the attributeChangedCallback method.
   *
   * @returns {string[]}
   */
  static get observedAttributes() {
    return ['data-fetched']
  }

  /**
   * Invoked when the custom elements attributes have been changed.
   *
   * Responsible for retrieving vin data (name, accolades, etc) and
   * attaching it to the custom element.
   *
   * @memberof Vehicle
   */
  attributeChangedCallback(name, oldValue, newValue) {
    this._vehicleData = Utils.getDataFromVin(this._vin)

    // shape for exposing minimum data.
    const returnData = {
      name: this._vehicleData.name,
      accolades: this._vehicleData.accolades,
      vins: this._vehicleData.vins,
      url: this._vehicleData.url
    }

    // Dispatch Event that vehicle data has been added.
    const event = new CustomEvent('onHaVehicleDataAdded', {
      bubbles: true,
      detail: returnData
    })
    this.dispatchEvent(event)
  }
}

export default Vehicle
